import { useEffect, useState } from "react";
import PaymentInfoRequest from "../../models/PaymentInfoRequest";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CreditCardPage } from "./CreditCardPage";

export const CreditCardWrapper: React.FC<{ setHttpErrorS: any, checkoutResource: any, fees: number, insecureEmail: string }> = (props) => {
    const stripePromise = loadStripe('pk_test_51Kw8dJChvEz3vBgdwXkgrM1Xn2hfV2zwrEIJLV0UtO24bsm9xrRM8ecGYZdZSRoMNHtnjkqkZwi7Gvkrx9mk4xv2009XvAOYLn');

    const [clientSecret, setClientSecret] = useState("")

    const options = {
        clientSecret
    }

    useEffect(() => {
        async function getClientSecret() {
            let paymentInfo = new PaymentInfoRequest(props.fees * 100, 'CAD', ("User@" + Date.now()));
    
            const url = `${process.env.REACT_APP_API}/payment/insecure/payment-intent`;
            const requestOptions = {
                method: 'POST',
                headers: {
                    //Authorization: `Bearer ${keycloak.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(paymentInfo)
            };
            const stripeResponse = await fetch(url, requestOptions);
            const stripeResponseJson = await stripeResponse.json();
            setClientSecret(stripeResponseJson.client_secret)
            console.log("Hi!!! :)")
        }

        getClientSecret();
    }, [props.fees])

    return (
        <>
            {clientSecret &&
                <Elements stripe={stripePromise} options={options}>
                    <CreditCardPage setHttpErrorS={props.setHttpErrorS} checkoutResource={props.checkoutResource}
                        fees={props.fees} insecureEmail={props.insecureEmail} />
                </Elements>
            }
        </>
    )
}